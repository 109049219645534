import React, { useEffect, useState } from 'react';
import './App.css';
import YouTubePlayer from './YouTubePlayer';

function App() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetch('/api/hello')
      .then((res) => res.json())
      .then((data) => setMessage(data.message));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
        <div className="header">
            <div className="header-button embosssmaller"></div>
            <div className="header-button menu-button embosssmaller"><img src="images/icons/menu.png"/></div>
        </div>
      <div>
          <YouTubePlayer/>
      </div>

        </div>
        <p>{message}</p>
      </header>
    </div>
    
  );
}

export default App;
